export default function(){
    return(
 <>

<section id="hero" class="hero">
      <div class="container position-relative">
        <div class="row gy-5" data-aos="fade-in">
          <div class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">
            <h2>Visual Web Scraping <span>Software</span></h2>
            <p>You can quickly scrape Text, HTML, Images, URLs, and Emails from any website with Transpire, and you can store the scraped data in a variety of formats.</p>
            <div class="d-flex justify-content-center justify-content-lg-start">
              <a href="download.html" class="btn-get-started">Download Free Trail</a>
              <a href="https://youtu.be/KzBldN8iq5U" class="glightbox btn-watch-video d-flex align-items-center"><i class="bi bi-play-circle"></i><span>Watch Video</span></a>
            </div>
          </div>
          <div class="col-lg-6 order-1 order-lg-2">
            <video width="110%" loop  autoPlay  muted>
            <source src="assets/img/video.mp4" class="img-fluid" alt="" data-aos="zoom-out" data-aos-delay="100"/></video>
          </div>
        </div>
      </div>

      <div class="icon-boxes position-relative">
        <div class="container position-relative">
          <div class="row gy-4 mt-5">

            <div class="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="100">
              <div class="icon-box">
                <div class="icon"><i class="bi bi-compass-fill"></i></div>
                <h4 class="title"><a href="" class="stretched-link">Simple Web Scraping</a></h4>
              </div>
            </div>

            <div class="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="200">
              <div class="icon-box">
                <div class="icon"><i class="bi bi-bezier2"></i></div>
                <h4 class="title"><a href="" class="stretched-link">Intelligent Pattern Detection</a></h4>
              </div>
            </div>

            <div class="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="300">
              <div class="icon-box">
                <div class="icon"><i class="bi bi-card-heading"></i></div>
                <h4 class="title"><a href="" class="stretched-link">Save to Database or File</a></h4>
              </div>
            </div>

            <div class="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="500">
              <div class="icon-box">
                <div class="icon"><i class="bi bi-amd"></i></div>
                <h4 class="title"><a href="" class="stretched-link">Deal with pagination</a></h4>
              </div>
            </div>
            <div class="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="500">
              <div class="icon-box">
                <div class="icon"><i class="bi bi-building-add"></i></div>
                <h4 class="title"><a href="" class="stretched-link">Enter keywords</a></h4>
              </div>
            </div>
            
            <div class="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="500">
              <div class="icon-box">
                <div class="icon"><i class="bi bi-shield-fill"></i></div>
                <h4 class="title"><a href="" class="stretched-link">Protecting privacy</a></h4>
              </div>
            </div>
            <div class="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="500">
              <div class="icon-box">
                <div class="icon"><i class="bi bi-tags-fill"></i></div>
                <h4 class="title"><a href="" class="stretched-link">Scraping by category</a></h4>
              </div>
            </div>
            <div class="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="500">
              <div class="icon-box">
                <div class="icon"><i class="bi bi-regex"></i></div>
                <h4 class="title"><a href="" class="stretched-link">Regular Expressions</a></h4>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <main id="main">


<section id="about" class="about">
  <div class="container" data-aos="fade-up">

    <div class="section-header">
      <h2>About Transpire Scraper</h2>
      <p>incredibly simple to use Transpire can handle login, form submission, navigation, pagination, categories, and keyword handling while scraping data from any website. supports scheduled scraping and proxies.</p>
    </div>

  </div>
</section>



<section id="stats-counter" class="stats-counter">
  <div class="container" data-aos="fade-up">

    <div class="row gy-4 align-items-center">

      <div class="col-lg-6">
        <img src="assets/img/stats-img.svg" alt="" class="img-fluid"/>
      </div>

      <div class="col-lg-6">

        <div class="stats-item d-flex align-items-center">
          <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" class="purecounter"></span>
          <p><strong>Happy Clients</strong> </p>
        </div>

        <div class="stats-item d-flex align-items-center">
          <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" class="purecounter"></span>
          <p><strong>Projects</strong> </p>
        </div>

        <div class="stats-item d-flex align-items-center">
          <span data-purecounter-start="0" data-purecounter-end="453" data-purecounter-duration="1" class="purecounter"></span>
          <p><strong>Hours Of Support</strong> </p>
        </div>

      </div>

    </div>

  </div>
</section>

<section id="services" class="services sections-bg">
  <div class="container" data-aos="fade-up">

    <div class="section-header">
      <h2>Transpire Features</h2>
    </div>

    <div class="row gy-4" data-aos="fade-up" data-aos-delay="100">

      <div class="col-lg-4 col-md-6">
        <div class="service-item  position-relative">
          <div class="icon">
            <i class="bi bi-activity"></i>
          </div>
          <h3>Simple Web Scraping</h3>
          <p>With Transpire's point-and-click interface, web scraping is simple. To scrape data, there is no need to create code or scripts. You will load and traverse websites using Transpire's built-in browser, and you may choose the data to be scraped with mouse clicks.</p>
          <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="service-item position-relative">
          <div class="icon">
            <i class="bi bi-broadcast"></i>
          </div>
          <h3>Intelligent Pattern Detection</h3>
          <p>Transpire recognises data patterns that appear in web pages automatically. There is no further configuration needed to scrape a list or table of items (name, address, email, price, etc.) from a web page. Transpire will automatically scrape repeating data.</p>
          <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="service-item position-relative">
          <div class="icon">
            <i class="bi bi-easel"></i>
          </div>
          <h3>Save to Database or File</h3>
          <p>A number of formats can be used to save scraped data. You can save the scraped data as an Excel, XML, CSV, JSON, or TSV file using the most recent version of Transpire's web scraping software (available for download at this link). The data can also be exported to a SQL database.</p>
          <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="service-item position-relative">
          <div class="icon">
            <i class="bi bi-bounding-box-circles"></i>
          </div>
          <h3>Protecting privacy</h3>
          <p>You can choose to access target websites through proxy servers or VPNs to scrape data invisibly and avoid having the web scraping programme blacklisted by web servers.It is possible to utilise a single proxy server or a collection of proxy servers.</p>
          <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="service-item position-relative">
          <div class="icon">
            <i class="bi bi-calendar4-week"></i>
          </div>
          <h3>Deal with pagination</h3>
          <p>Websites frequently present information like product listings or search results across numerous pages. Data from numerous pages can be automatically crawered and scraped by Transpire. Simply point out the "connection to the next page," and Transpire will gather information from every page.</p>
          <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="service-item position-relative">
          <div class="icon">
            <i class="bi bi-chat-square-text"></i>
          </div>
          <h3>Enter keywords</h3>
          <p>By automatically submitting a list of input terms to search forms, you can scrape data. To do a search, enter any number of terms in any of the input text fields.It is possible to scrape information from search results for all possible word combinations.</p>
          <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
        </div>
      </div>
      
      <div class="col-lg-4 col-md-6">
        <div class="service-item position-relative">
          <div class="icon">
            <i class="bi bi-tags-fill"></i>
          </div>
          <h3>Scraping by category</h3>
          <p>Transpire gives you the ability to scrape information from a list of links that point to related pages or listings on a website. By using a single configuration, you may use this to scrape categories and subcategories within webpages.</p>
          <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
        </div>
      </div>

      
      <div class="col-lg-4 col-md-6">
        <div class="service-item position-relative">
          <div class="icon">
            <i class="bi bi-regex"></i>
          </div>
          <h3>Regular Expressions</h3>
          <p>Regular Expressions (RegEx) may be used to scrape the relevant section from web pages' Text or HTML sources. You have more freedom and control while data-scraping with this effective method.</p>
          <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
        </div>
      </div>

      
      <div class="col-lg-4 col-md-6">
        <div class="service-item position-relative">
          <div class="icon">
            <i class="bi bi-filetype-js"></i>
          </div>
          <h3>JavaScript Support</h3>
          <p>Before scraping data, run your own JavaScript code in the browser. This can be used to interact with page elements, alter the DOM, or call JavaScript functions that are already present on the target page.</p>
          <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
        </div>
      </div>

      
      <div class="col-lg-4 col-md-6">
        <div class="service-item position-relative">
          <div class="icon">
            <i class="bi bi-images"></i>
          </div>
          <h3>Scraping of images</h3>
          <p>You can either download images or scrape picture URLs. Multiple photos that are displayed on product detail pages of eCommerce websites can be automatically scraped by Transpire.</p>
          <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
        </div>
      </div>

      
      <div class="col-lg-4 col-md-6">
        <div class="service-item position-relative">
          <div class="icon">
            <i class="bi bi-window-fullscreen"></i>
          </div>
          <h3>Script browser operations</h3>
          <p>Transpire can be easily configured to perform tasks like Clicking Links, Selecting List/Drop-down Options, Input Text to a field, Scrolling page, Opening Popups etc</p>
          <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
        </div>
      </div>

      
      <div class="col-lg-4 col-md-6">
        <div class="service-item position-relative">
          <div class="icon">
            <i class="bi bi-question-circle-fill"></i>
          </div>
          <h3>Technical support</h3>
          <p>For a year after the date of purchase, if you acquire Transpire, you will receive free updates and free support from us.</p>
          <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
        </div>
      </div>

    </div>

  </div>
</section>

<section id="testimonials" class="testimonials">
  <div class="container" data-aos="fade-up">

    <div class="section-header">
      <h2>Transpire Reviews</h2>
    </div>

    <div class="slides-3 swiper" data-aos="fade-up" data-aos-delay="100">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <div class="testimonial-wrap">
            <div class="testimonial-item">
              <div class="d-flex align-items-center">
                <img src="assets/img/testimonials/testimonials-1.jpg" class="testimonial-img flex-shrink-0" alt=""/>
                <div>
                  <h3>Ahmad Khan</h3>
                  <h4>Independent Expert</h4>
                  <div class="stars">
                    <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                  </div>
                </div>
              </div>
              <p>
                <i class="bi bi-quote quote-icon-left"></i>
                A GUI is used for configuration, which makes it simple to set up initially but gives the tool further flexibility. I continue to discover new techniques every day for returning data that is even richer, including employing regular expressions or pop-up data scraping.
                <i class="bi bi-quote quote-icon-right"></i>
              </p>
            </div>
          </div>
        </div>

        <div class="swiper-slide">
          <div class="testimonial-wrap">
            <div class="testimonial-item">
              <div class="d-flex align-items-center">
                <img src="assets/img/testimonials/testimonials-2.jpg" class="testimonial-img flex-shrink-0" alt="" />
                <div>
                  <h3>Sara Wilsson</h3>
                  <h4>Designer</h4>
                  <div class="stars">
                    <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                  </div>
                </div>
              </div>
              <p>
                <i class="bi bi-quote quote-icon-left"></i>
                For around three to four years, I have utilised and taught using Transpire. For my studies and business initiatives, I use it. The programme is simple to use and retrieves data as required. It is also quick, and further modifications since its creation have only made it quicker. The tech support team provides prompt and courteous responses to all inquiries.
                <i class="bi bi-quote quote-icon-right"></i>
              </p>
            </div>
          </div>
        </div>

        <div class="swiper-slide">
          <div class="testimonial-wrap">
            <div class="testimonial-item">
              <div class="d-flex align-items-center">
                <img src="assets/img/testimonials/testimonials-3.jpg" class="testimonial-img flex-shrink-0" alt=""/>
                <div>
                  <h3>Jena Karlis</h3>
                  <h4>Data Analytics</h4>
                  <div class="stars">
                    <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                  </div>
                </div>
              </div>
              <p>
                <i class="bi bi-quote quote-icon-left"></i>
                This tool has been used by me on numerous data mining and lead generating projects, among other things. I've always been quite happy with the outcomes. It has a wide range of applications for different data scraping circumstances, making it incredibly adaptable.
                <i class="bi bi-quote quote-icon-right"></i>
              </p>
            </div>
          </div>
        </div>

        <div class="swiper-slide">
          <div class="testimonial-wrap">
            <div class="testimonial-item">
              <div class="d-flex align-items-center">
                <img src="assets/img/testimonials/testimonials-4.jpg" class="testimonial-img flex-shrink-0" alt=""/>
                <div>
                  <h3>Matt Brandon</h3>
                  <h4>Freelancer</h4>
                  <div class="stars">
                    <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                  </div>
                </div>
              </div>
              <p>
                <i class="bi bi-quote quote-icon-left"></i>
                Fantastic. They really sent me videos that demonstrated how to use a few of the scrapers we were developing. The product's quality and assistance have me incredibly happy. It takes a little getting used to in order to carry out some tasks.
                <i class="bi bi-quote quote-icon-right"></i>
              </p>
            </div>
          </div>
        </div>

      </div>
      <div class="swiper-pagination"></div>
    </div>

  </div>
</section>

<section id="pricing" class="pricing sections-bg">
  <div class="container" data-aos="fade-up">

    <div class="section-header">
      <h2>Buy  License</h2>
      <p>MyCommerce Share-It!, a Digital River company that manages sales for thousands of software companies globally, manages our online orders. Credit cards, PayPal, bank/wire transfers, checks, purchase orders (only for corporate clients), and billing in other currencies are all acceptable forms of payment.</p>
    </div>

    <div class="row g-4 py-lg-5" data-aos="zoom-out" data-aos-delay="100">

      <div class="col-lg-4">
        <div class="pricing-item">
          <h3>1 User Plan</h3>
          <div class="icon">
            <i class="bi bi-box"></i>
          </div>
          <h4><sup>$</sup>100<span> / year</span></h4>
          <ul>
            <li><i class="bi bi-check"></i> 1 User/Computer</li>
            <li><i class="bi bi-check"></i> Free Updates for a Year</li>
            <li><i class="bi bi-check"></i>1 year of email assistance</li>
            <li class="na"><i class="bi bi-x"></i> <span>Access for life to current versions</span></li>
          </ul>
          <div class="text-center"><a href="#" class="buy-btn">Buy Now</a></div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="pricing-item featured">
          <h3>Unlimited User Plan</h3>
          <div class="icon">
            <i class="bi bi-airplane"></i>
          </div>

          <h4><sup>$</sup>600<span> / year</span></h4>
          <ul>
            <li><i class="bi bi-check"></i> Unlimited User</li>
            <li><i class="bi bi-check"></i> Free Updates for a Year</li>
            <li><i class="bi bi-check"></i> 1 year of email assistance</li>
            <li><i class="bi bi-check"></i> Access for life to current versions</li>
          </ul>
          <div class="text-center"><a href="#" class="buy-btn">Buy Now</a></div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="pricing-item">
          <h3>2 User Plan</h3>
          <div class="icon">
            <i class="bi bi-send"></i>
          </div>
          <h4><sup>$</sup>150<span> / year</span></h4>
          <ul>
            <li><i class="bi bi-check"></i> 2 User/Cumputer </li>
            <li><i class="bi bi-check"></i> Free Updates for a Year</li>
            <li><i class="bi bi-check"></i> 1 year of email assistance</li>
            <li><i class="bi bi-check"></i> Access for life to current versions</li>
          </ul>
          <div class="text-center"><a href="#" class="buy-btn">Buy Now</a></div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="pricing-item">
          <h3>3 User Plan</h3>
          <div class="icon">
            <i class="bi bi-send"></i>
          </div>
          <h4><sup>$</sup>200<span> / year</span></h4>
          <ul>
            <li><i class="bi bi-check"></i> 3 User/Cumputer </li>
            <li><i class="bi bi-check"></i> Free Updates for a Year</li>
            <li><i class="bi bi-check"></i> 1 year of email assistance</li>
            <li><i class="bi bi-check"></i> Access for life to current versions</li>
          </ul>
          <div class="text-center"><a href="#" class="buy-btn">Buy Now</a></div>
        </div>
      </div>


      
      <div class="col-lg-4">
        <div class="pricing-item">
          <h3>5 User Plan</h3>
          <div class="icon">
            <i class="bi bi-send"></i>
          </div>
          <h4><sup>$</sup>300<span> / year</span></h4>
          <ul>
            <li><i class="bi bi-check"></i> 5 User/Cumputer </li>
            <li><i class="bi bi-check"></i> Free Updates for a Year</li>
            <li><i class="bi bi-check"></i> 1 year of email assistance</li>
            <li><i class="bi bi-check"></i> Access for life to current versions</li>
          </ul>
          <div class="text-center"><a href="#" class="buy-btn">Buy Now</a></div>
        </div>
      </div>
    </div>

  </div>
</section>

<section id="recent-posts" class="recent-posts sections-bg">
  <div class="container" data-aos="fade-up">

    <div class="section-header">
      <h2>How to gather data anonymously?</h2>
      <p>Maintain your privacy when data-crawling. Avoid being obstructed.</p>
    </div>

    <div class="row gy-4">

      <div class="col-xl-4 col-md-6">
        <article>

          <div class="post-img">
            <img src="assets/img/blog/blog-1.jpg" alt="" class="img-fluid"/>
          </div>

          <p class="post-category">Handling login & CAPTCHA</p>

          <h2 class="title">
            <a href="blog-details.html">How do I handle the CAPTCHA and login forms while scraping?</a>
          </h2>

          <div class="d-flex align-items-center">
            <div class="post-meta">
              <p class="post-author">Admin</p>
              <p class="post-date">
                <time datetime="2022-01-01">Jan 1, 2023</time>
              </p>
            </div>
          </div>

        </article>
      </div>

      <div class="col-xl-4 col-md-6">
        <article>

          <div class="post-img">
            <img src="assets/img/blog/blog-2.jpg" alt="" class="img-fluid"/>
          </div>

          <p class="post-category">Regular Expression</p>

          <h2 class="title">
            <a href="blog-details.html">How do I use Transpire with Regular Expressions?</a>
          </h2>

          <div class="d-flex align-items-center">
            <div class="post-meta">
              <p class="post-author">Admin</p>
              <p class="post-date">
                <time datetime="2022-01-01">Jun 5, 2023</time>
              </p>
            </div>
          </div>

        </article>
      </div>

      <div class="col-xl-4 col-md-6">
        <article>

          <div class="post-img">
            <img src="assets/img/blog/blog-3.jpg" alt="" class="img-fluid"/>
          </div>

          <p class="post-category">Real State</p>

          <h2 class="title">
            <a href="blog-details.html">How can I scrape information about properties from real estate websites?</a>
          </h2>

          <div class="d-flex align-items-center">
            <div class="post-meta">
              <p class="post-author">Admin</p>
              <p class="post-date">
                <time datetime="2022-01-01">Jun 22, 2023</time>
              </p>
            </div>
          </div>

        </article>
      </div>

    </div>

  </div>
</section>

<section id="contact" class="contact">
  <div class="container" data-aos="fade-up">

    <div class="section-header">
      <h2>Contact</h2>
      <p>Except on weekends, when the response time may be closer to 48 hours, your questions will be responded to within 24 hours.</p>
    </div>

    <div class="row gx-lg-0 gy-4">

      <div class="col-lg-4">

        <div class="info-container d-flex flex-column align-items-center justify-content-center">
        
          <div class="info-item d-flex">
            <i class="bi bi-envelope flex-shrink-0"></i>
            <div>
              <h4>Email:</h4>
              <p>support@transpreit.com</p>
            </div>
          </div>

        
        </div>

      </div>

      <div class="col-lg-8">
        <form action="/forms/contact.php" method="post" role="form" class="php-email-form">
          <div class="row">
            <div class="col-md-6 form-group">
              <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required/>
            </div>
            <div class="col-md-6 form-group mt-3 mt-md-0">
              <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required/>
            </div>
          </div>
          <div class="form-group mt-3">
            <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required/>
          </div>
          
          <div class="form-group mt-3">
            <textarea class="form-control" name="message" rows="7" placeholder="Message" required></textarea>
          </div>
          <div class="my-3">
            <div class="loading">Loading</div>
            <div class="error-message"></div>
            <div class="sent-message">Your message has been sent. Thank you!</div>
          </div>
          <div class="text-center"><button type="submit">Send Message</button></div>
        </form>
      </div>

    </div>

  </div>
</section>

</main>
 </>
    );
}