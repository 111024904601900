import { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import "./topbar.css";

export default function TopBar() {
  const { user, dispatch } = useContext(Context);
  const PF = "http://localhost:5000/images/"

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  };
  return (
  <><section id="topbar" class="topbar d-flex align-items-center">
      <div class="container d-flex justify-content-center justify-content-md-between">
        <div class="contact-info d-flex align-items-center">
          <i class="bi bi-envelope d-flex align-items-center"><a href="mailto:contact@example.com">support@transpreit.com</a></i>
          <i class="bi bi-phone d-flex align-items-center ms-4"><span>+92 342 9825632</span></i>
        </div>
        <div class="social-links d-none d-md-flex align-items-center">
        <a href="https://twitter.com/home" class="twitter"><i class="bi bi-twitter"></i></a>
        <a href="https://web.facebook.com/profile.php?id=100091716798662" class="facebook"><i class="bi bi-facebook"></i></a>
        <a href="https://www.youtube.com/@TranspireScraper/videos" class="youtube"><i class="bi bi-youtube"></i></a>
        </div>
      </div>
    </section>  
    <header id="header" class="header d-flex align-items-center">
<div class="container-fluid container-xl d-flex align-items-center justify-content-between">
  <a href="/" class="logo d-flex align-items-center">
    <h1>Transpire<span>.</span></h1>
  </a>
  <nav id="navbar" class="navbar">
    <ul>
      <li><a href="/">Home</a></li>
      <li><a href="#about">About</a></li>
      <li><a href="#services">Services</a></li>
      <li><a  href="/blog">
      Blog
            </a></li>
      <li><a href="#contact">Contact</a></li>
      <li><a href="#pricing"  class="btn btn-dark">Buy</a></li>
      <li className="topListItem" onClick={handleLogout}>
            {user && "LOGOUT"}</li>
    </ul>
  </nav>
  <i class="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
  <i class="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
</div>
</header></>
  );
}
