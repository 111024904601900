export default function footers(){
    return(
    <><footer id="footer" class="footer">
            <div class="container">
                <div class="row gy-4">
                    <div class="col-lg-5 col-md-12 footer-info">
                        <a href="index.html" class="logo d-flex align-items-center">
                            <span>Transpire</span>
                        </a>
                        <p>Early in 2020, Transpire released Transpire Scraper with the goal of creating a user-friendly yet potent online scraping tool. One of the first point-and-click user interfaces for visual online scraping tools, Transpire Scraper , allowed users to choose data from web sites. For a variety of online scraping needs, we have worked with thousands of customers and hundreds of different websites throughout the years.</p>
                        <div class="social-links d-flex mt-4">
                            <a href="https://twitter.com/home" class="twitter"><i class="bi bi-twitter"></i></a>
                            <a href="https://web.facebook.com/profile.php?id=100091716798662" class="facebook"><i class="bi bi-facebook"></i></a>
                            <a href="https://www.youtube.com/@TranspireScraper/videos" class="youtube"><i class="bi bi-youtube"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                        <h4>Contact Us</h4>
                       <p>
                        <strong>Email:</strong> support@transpreit.com<br/>
                        </p>

                    </div>

                </div>
            </div>

            <div class="container mt-4">
                <div class="copyright">
                    &copy; Copyright <strong><span>Transpire </span></strong>. All Rights Reserved
                </div>
                <div class="credits">
                    Designed by <a href="https://bootstrapmade.com/">Transpire</a>
                </div>
            </div>

        </footer>
              <a href="#" class="scroll-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
              <div id="preloader"></div>
            </>
    );
}